<template>
  <div class="workflow">
    <div
      v-if="errorFetchingWorkflow"
      class="text-center"
      style="padding: 200px 0"
    >
      <v-btn color="primary" outlined @click="getWorkflowbyId"
        ><v-icon>mdi-refresh</v-icon> Retry</v-btn
      >
    </div>

    <div class="loaderx" v-if="isLoadingWorkflow">
      <v-skeleton-loader height="200px" type="card" />

      <div class="vertical-line"></div>

      <v-skeleton-loader height="200px" type="card" />

      <div class="vertical-line"></div>

      <v-skeleton-loader height="200px" type="card" />
    </div>

    <div
      class="workflow__wrapper"
      v-if="!errorFetchingWorkflow && !isLoadingWorkflow"
    >
      <div class="analytics" v-if="!$vuetify.breakpoint.smAndDown">
        <details-tab-workflow
          :runs="workflow.runs"
          :trigger="workflow.trigger"
          :schema="workflow.conditions"
          :inputs="workflow.inputs"
        />
      </div>

      <div class="flow-setup">
        <div class="d-flex pr-5" style="align-items: center; width: 100%">
          <v-breadcrumbs :items="breadcrumbs" style="font-weight: 600">
            <template v-slot:divider>
              <v-icon class="px-0">mdi-chevron-right</v-icon>
            </template>
          </v-breadcrumbs>
          <v-spacer></v-spacer>

          <v-btn @click="$router.go(-1)" text rounded large>
            <v-icon left>mdi-chevron-left</v-icon> Back
          </v-btn>
        </div>

        <div class="flows">
          <div class="flow-setup__trigger">
            <div class="header" @click="showTriggers = !showTriggers">
              <span class="d-flex items-center">
                <v-btn color="#8F96A1" icon
                  ><v-icon size="33"
                    >mdi-chevron-right-circle-outline</v-icon
                  ></v-btn
                >
                <span class="title pl-1">{{ workflow.title }}</span></span
              >
              <v-btn color="primary" icon
                ><v-icon size="33" v-if="!showTriggers"
                  >mdi-chevron-down</v-icon
                >
                <v-icon size="33" v-else>mdi-chevron-up</v-icon>
              </v-btn>
            </div>
            <div>
              <transition name="animate-down">
                <trigger-workflow
                  v-if="showTriggers"
                  v-model="workflow.trigger"
                  disabled
                />
              </transition>
            </div>
          </div>

          <payment-trigger
            ref="paymentTrigger"
            v-model="workflow.payment"
            :isVisable="isPaymentTrigger"
            :isEdit="true"
            v-if="isPaymentTrigger"
          />
          <form-trigger
            ref="formTrigger"
            v-model="workflow.form"
            :isEdit="true"
            :isVisable="isFormTrigger"
            v-if="isFormTrigger"
          />

          <compose-workflow
            ref="conditions"
            v-model="workflow.conditions"
            :isVisable="canShowConditions"
            :trigger="workflow.trigger"
            @continue="workflow.composerIsReady = true"
            @inputs="workflow.inputs = $event"
            :trigger-data="
              isFormTrigger
                ? workflow.form
                : isPaymentTrigger
                ? workflow.payment
                : null
            "
            v-if="canShowConditions"
            :isEdit="true"
            @selected-fields="workflow.fields = $event"
          />

          <execute-actions-workflow
            v-if="canShowActions"
            ref="actions"
            :trigger="workflow.trigger"
            :organizationId="orgId.toString()"
            @publish="
              $route.query.template
                ? $refs.createWorkflow.start()
                : $refs.updateWorkflow.start()
            "
            :isVisable="canShowActions"
            v-model="workflow.actions"
            :exception="workflow.exception"
            @exception="workflow.exception = $event"
            :exceptions="workflow.exceptionSchema"
            @exception-schema="workflow.exceptionSchema = $event"
            :inputs="workflow.inputs"
          />
        </div>
      </div>
    </div>

    <create-workflow
      ref="createWorkflow"
      :workflow="workflowPayload"
      @done="$router.replace('/workflow')"
    />

    <update-workflow
      ref="updateWorkflow"
      :workflow="workflowPayload"
      :id="$route.params.id"
      @done="$router.replace('/workflow')"
    />
  </div>
</template>

<script>
import ExecuteActionsWorkflow from "../../components/workflow/actions/execute-actions-workflow.vue";
import ComposeWorkflow from "../../components/workflow/compose/compose-workflow.vue";
import detailsTabWorkflow from "../../components/workflow/details-tab-workflow.vue";
import TriggerWorkflow from "../../components/workflow/trigger-workflow.vue";
import FormTrigger from "../../components/workflow/trigger/form-trigger.vue";
import PaymentTrigger from "../../components/workflow/trigger/payment-trigger.vue";
import UpdateWorkflow from "../../components/workflow/update-workflow.vue";
import createWorkflow from "../../components/workflow/create-workflow.vue";

export default {
  components: {
    detailsTabWorkflow,
    TriggerWorkflow,
    PaymentTrigger,
    FormTrigger,
    ComposeWorkflow,
    ExecuteActionsWorkflow,
    UpdateWorkflow,
    createWorkflow,
  },
  data() {
    {
      return {
        updateDialog: false,
        isLoadingWorkflow: false,
        errorFetchingWorkflow: false,
        updateDialogSucessful: false,
        showTriggers: false,
        scrollOptions: {
          duration: 500,
          offset: 0,
          easing: "easeInOutCubic",
          container: ".flows",
        },
        breadcrumbs: [
          {
            text: "Workflow",
            to: "/workflow",
            disabled: true,
          },
          {
            text: "rule",
            to: "#",
            disabled: true,
          },
          {
            text: "approval",
            disabled: false,
          },
        ],
        showAdvance: false,
        hypn_id: "",
        workflow: {
          title: "",
          trigger: "",
          runs: 0,
          conditions: null,
          fields: null,
          payment: null,
          form: null,
          composerIsReady: false,
          inputs: null,
          actions: [],
          webhook: "",
          exception: false,
          exceptionSchema: null,
        },
        isUpdatingDialog: false,

        publishDialog: false,
        publishDialogSucessful: false,
        isPublishingWorkflow: false,
      };
    }
  },
  mounted() {
    this.getWorkflowbyId();
  },

  methods: {
    async getWorkflowbyId() {
      this.errorFetchingWorkflow = false;
      try {
        this.isLoadingWorkflow = true;
        const { data } = await this.$store.dispatch(
          this.$route.query.template
            ? "workflow/getWorkflowTemplateById"
            : "workflow/getWorkflowById",
          this.$route.params.id
        );

        // templates/[id]

        const workflowx = data.data;

        console.log("workflowx...", JSON.stringify(workflowx, null, 2));

        this.workflow.title = workflowx.workflow_title;
        this.breadcrumbs[2].text = this.workflow.title;
        this.workflow.trigger = workflowx.source;
        this.workflow.form = workflowx.form?.id;
        this.workflow.payment = workflowx?.payment;
        this.workflow.actions = workflowx.workflow_schema.actions;
        this.workflow.runs = workflowx.run;

        let condition = workflowx.workflow_schema.condition;

        // console.log(JSON.stringify("conditon...", condition, null, 2));

        this.workflow.conditions = condition;
        this.workflow.exception = workflowx?.exception || true;
        this.workflow.exceptionSchema = workflowx?.exception_schema || null;

        this.showTriggers = true;

        if (this.$route.query.template && this.$route.query.name) {
          this.workflow.title = this.$route.query.name;
          this.breadcrumbs[2].text = this.workflow.title;
        }
      } catch (error) {
        this.errorFetchingWorkflow = true;
        this.showToast({
          sclass: "error",
          show: true,
          message: error.msg,
          timeout: 3000,
        });

        if (error.status === 401) {
          this.$router.replace("/workflow");
        }
      } finally {
        this.isLoadingWorkflow = false;
      }
    },
  },

  watch: {
    "workflow.trigger": {
      deep: true,
      handler() {
        // this.workflow.form = null;
        // this.workflow.payment = null;
        // this.workflow.conditions = null;
      },
    },

    workflowPayload: {
      deep: true,
      immediate: true,
      handler(val) {
        if (process.env.NODE_ENV === "development") {
          console.log(JSON.stringify(val, null, 2));
        }
      },
    },

    canShowConditions(val) {
      if (val) {
        this.$nextTick(() => {
          this.$vuetify.goTo(this.$refs.conditions, this.scrollOptions);
        });
      }
    },

    canShowActions(val) {
      if (val) {
        this.$nextTick(() => {
          this.$vuetify.goTo(this.$refs.actions, this.scrollOptions);
        });
      }
    },

    isPaymentTrigger(val) {
      if (val) {
        this.$nextTick(() => {
          this.$vuetify.goTo(this.$refs.paymentTrigger, this.scrollOptions);
        });
      }
    },

    isFormTrigger(val) {
      if (val) {
        this.$nextTick(() => {
          this.$vuetify.goTo(this.$refs.formTrigger, this.scrollOptions);
        });
      }
    },
  },

  computed: {
    canShowConditions() {
      return (
        this.isInvoiceTrigger ||
        this.workflow.payment !== null ||
        this.workflow.form !== null
      );
    },

    canShowActions() {
      return this.workflow.composerIsReady;
    },

    isPaymentTrigger() {
      if (this.workflow.trigger && this.workflow.trigger == "payment") {
        return true;
      }
      return false;
    },

    isFormTrigger() {
      if (this.workflow.trigger && this.workflow.trigger == "form") {
        return true;
      }
      return false;
    },

    isInvoiceTrigger() {
      if (this.workflow.trigger && this.workflow.trigger == "invoice") {
        return true;
      }
      return false;
    },

    workflowPayload() {
      return {
        user: this.$store.getters["auth/user"]?.id,
        source: this.workflow.trigger,
        organization: this.orgId,
        workflow_schema: {
          condition: this.workflow.conditions,
          actions: this.workflow.actions,
        },
        trigger_schema: this.workflow.fields,
        form: this.workflow.form,
        payment: this.workflow.payment,
        is_active: 1,
        workflow_title: this.workflow.title,
        exception: this.workflow.exception,
        exception_schema: this.workflow.exceptionSchema,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.items-center {
  align-items: center;
}

.loaderx {
  display: flex;

  padding: 30px;
  flex-direction: column;
}

.vertical-line {
  display: block;
  background-color: #d9dee1;

  margin: auto;
  height: 80px;
  width: 2px;
}

.workflow {
  height: 100%;

  &__wrapper {
    // display: flex;
    display: relative;
    height: 100%;
    padding: 10px 0px;

    .analytics {
      width: 31%;
      height: 100%;
      position: absolute;
      padding-top: 30px;
      left: 0;
      top: 0;
      bottom: 0;
    }

    .flows {
      flex: 1;
      overflow: auto;
      padding: 20px;
    }

    .flow-setup {
      position: absolute;
      // flex: 1;
      height: 100%;
      width: 69%;
      right: 0;
      top: 0;
      bottom: 0;
      display: flex;
      overflow: hidden;
      flex-direction: column;

      // mobile view
      @media (max-width: 768px) {
        width: 100%;
      }

      &__trigger {
        width: 100%;
        padding: 30px;
        background: #fff;
        box-shadow: 0px 4px 16px rgba(204, 188, 252, 0.15);
        border-radius: 6px;

        .header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;

          .title {
            font-size: 30px;
            font-weight: 700;
            color: #8f96a1;
            text-transform: capitalize;

            @media (max-width: 768px) {
              font-size: 20px;
            }
          }
        }
      }
    }
  }
}

.animate-down {
  &enter {
    transform: translateY(-10px);
    opacity: 0;
  }
  &enter-active,
  &leave-active {
    transition: all 0.3s ease-out;
  }

  &leave-to {
    opacity: 0;
    transform: translateY(-10px);
  }
}
</style>
